@media print {
  #ngx-print-element,
  #ngx-print-element * {
    visibility: visible;
  }
  #ngx-print-element {
    position: absolute;
    left: 0;
    top: 0;
  }
  .print-none {
    display: none;
  }
}
